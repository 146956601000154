import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideToLeft,
  slideToRight,
} from "../../../../../../utils/animations";
import {
  H4,
  H5,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";
import { PageChange } from "../../../../../common/Scroll";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  & > *:nth-child(2) {
    opacity: 0;
    animation: ${fadeIn} 0.6s linear;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
  }
`;

export const BottomContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  height: 550px;
`;

export const Title = styled.h4`
  ${H5}
  margin-bottom: 24px;
  color: #013cff;
  white-space: pre-line;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin: 0;
  color: #151515;
  white-space: pre-line;

  a,
  a:visited,
  a:link {
    font-weight: 700;
    color: #013cff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const ImageWrapper = styled.div`
  height: calc(100vh - 72px);
  margin: 0;
  padding: 0;
`;

export const Image = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  display: block;
  margin: 0;
  max-width: 100%;
  height: 100%;
`;

export const ImageContain = styled(Image)`
  min-height: 100vh;

  img {
    margin: 0;
    padding: 96px 0;
    object-fit: contain !important;
  }
`;

interface SlideTransitionProps {
  slideChange: PageChange;
}

export const SlideTransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface ArrowsWrapperProps {
  arrowColor?: string;
}

export const ArrowsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 550px;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(50%, 2);

  svg > rect {
    ${(props: ArrowsWrapperProps) =>
      props.arrowColor && `fill: ${props.arrowColor} !important;`};
  }
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;
