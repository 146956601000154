import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  ContentWrapper,
  Title,
  ImageWrapper,
  Image,
  ImageContain,
  SlideTransitionWrapper,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { PageThemeType } from "../../../../../../utils/types";
import { PageTitle } from "../../../../../common/PageTitle";
import ArrowLeft from "-!svg-react-loader!../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";

interface IChildImageSharp {
  fluid: FluidObject;
}
interface INode {
  name: string;
  childImageSharp: IChildImageSharp;
}
interface CSRMobileProps {
  pageChange: PageChange;
  pageThemeChange?: (theme?: PageThemeType) => void;
}

const AknowledgementsMobile: React.FC<CSRMobileProps> = ({
  pageChange,
  pageThemeChange,
}) => {
  const nOfSlides = 3;

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  const { t } = useTranslation("main");
  const data = useStaticQuery(imagesQuery);
  const { nodes }: { nodes: INode[] } = data.allFile;
  const images = nodes.map((node: INode) => node.childImageSharp.fluid);

  useEffect(() => {
    return () => {
      pageThemeChange && pageThemeChange(undefined);
    };
  }, []);

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        const newSlide = slide - 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        const newSlide = slide + 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`acknowledgements`)} />
      <PageWrapper animation={pageChange}>
        <ContentWrapper>
          <Title>{t(`acknowledgements`)}</Title>
        </ContentWrapper>
        <SlideTransitionWrapper slideChange={slideTransition}>
          {slide === 0 ? (
            <ImageWrapper>
              <Image fluid={images[0]} />
            </ImageWrapper>
          ) : slide === 1 ? (
            <ImageWrapper>
              <Image fluid={images[1]} />
            </ImageWrapper>
          ) : slide === 2 ? (
            <ImageWrapper>
              <Image fluid={images[2]} />
            </ImageWrapper>
          ) : (
            slide === 3 && <ImageContain fluid={images[3]} />
          )}
        </SlideTransitionWrapper>
        <ArrowsWrapper>
          <LeftArrowWrapper
            slideChange={slide === 1 ? slideTransition : undefined}
          >
            {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
          </LeftArrowWrapper>
          <RightArrowWrapper
            slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
          >
            {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
          </RightArrowWrapper>
        </ArrowsWrapper>
      </PageWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "acknowledgements" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default AknowledgementsMobile;
