import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  ContentWrapper,
  Title,
  Description,
  ImageWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import ImageSlider from "../../../../../common/ImageSlider";
import { PageTitle } from "../../../../../common/PageTitle";

interface CSRProps {
  pageChange: PageChange;
}

const Aknowledgements: React.FC<CSRProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const data = useStaticQuery(imagesQuery);

  return (
    <>
      <PageTitle subtitle={t(`acknowledgements`)} />
      <PageWrapper animation={pageChange}>
        <ContentWrapper>
          <Title>{t(`acknowledgements`)}</Title>
        </ContentWrapper>
        <ImageWrapper>
          <ImageSlider
            images={data && data.allFile.nodes}
            imageFitStyleArray={[
              ...data.allFile.nodes.map((_: any) => "contain"),
            ]}
          />
        </ImageWrapper>
      </PageWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "acknowledgements" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default Aknowledgements;
