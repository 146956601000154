import React, { useState, useEffect, useRef } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

import Navigation, { NavigationRefMethods } from "../../common/Navigation";
import {
  TransitionBlock,
  TransitionRandomBlock,
  PageWrapper,
  TransitionWrapper,
  EUModalWrapper,
  DesktopLogo,
  ContentWrapper,
  Title,
  PageTitleWrapper,
  PageSubtitleWrapper,
  Team,
  TopVector,
  BottomVector,
  Navbar,
} from "./style";
import Scroll, { PageChange } from "../../common/Scroll";
import { PageTitle } from "../../common/PageTitle";
import EUModal from "../../common/EUModal";
import Loader from "../../common/Loader";
import ContactButton from "../../common/ContactButton";
import NewsButton from "../../common/NewsButton";
import SMWidget from "../../common/SMWidget";
import { LocationProp, PageThemeType } from "../../../utils/types";
import { device } from "../../../utils/breakpoints";
import Aknowledgements from "./components/CSR copy/Desktop";
import AknowledgementsMobile from "./components/CSR copy/Mobile";

const loadableOptions = {
  fallback: <Loader fullPage width={100} height={100} />,
};
const WhoWeAreMobile = loadable(
  () => import("./components/WhoWeAre/Mobile"),
  loadableOptions
);
const TechnologiesMobile = loadable(
  () => import("./components/Technologies/Mobile"),
  loadableOptions
);
const HistoryMobile = loadable(
  () => import("./components/History/Mobile"),
  loadableOptions
);
const ClientsMobile = loadable(
  () => import("./components/Clients/Mobile"),
  loadableOptions
);
const CSRMobile = loadable(
  () => import("./components/CSR/Mobile"),
  loadableOptions
);
const EKOMobile = loadable(
  () => import("./components/EKO/Mobile"),
  loadableOptions
);
const OKEMobile = loadable(
  () => import("./components/OKE/Mobile"),
  loadableOptions
);
const WhoWeAreDesktop = loadable(
  () => import("./components/WhoWeAre/Desktop"),
  loadableOptions
);
const TechnologiesDesktop = loadable(
  () => import("./components/Technologies/Desktop"),
  loadableOptions
);
const HistoryDesktop = loadable(
  () => import("./components/History/Desktop"),
  loadableOptions
);
const ClientsDesktop = loadable(
  () => import("./components/Clients/Desktop"),
  loadableOptions
);
const CSRDesktop = loadable(
  () => import("./components/CSR/Desktop"),
  loadableOptions
);
const EKODesktop = loadable(
  () => import("./components/EKO/Desktop"),
  loadableOptions
);
const OKEDesktop = loadable(
  () => import("./components/OKE/Desktop"),
  loadableOptions
);

const pageThemes = [
  "white",
  "white",
  "white",
  "blue",
  "white",
  "blue",
  "white",
  "white",
];
const navigationPageThemes = "black";

const transitionBlocks = [...Array(20)].map((_, index) => (
  <TransitionBlock key={index} className="whiteBlock" />
));
const transitionRandomBlocks = Array.from(Array(6).keys()).map(c =>
  Array.from(Array(4).keys()).map(r => (
    <TransitionRandomBlock
      key={c + r}
      delay={Math.floor(Math.random() * (600 - 64 + 1)) + 64}
    />
  ))
);

const MainPage: React.FC<LocationProp> = ({ location }) => {
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<PageChange>();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileOnly);
  const [modal, setModal] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(true);

  const navRef = useRef<NavigationRefMethods>(null);
  const isSmallWindow = useMediaQuery({ query: device.mobile });
  const img = useStaticQuery(query);
  const { t } = useTranslation("main");

  useEffect(() => {
    setIsMobile(isMobileOnly);
  }, [isMobileOnly]);

  const goToMainPage = () => {
    setPage(0);
    navigate("/");
  };

  return isMobile || isSmallWindow ? (
    <>
      {/* <NavigationBar>
        <LogoMobile onClick={goToMainPage} /> */}
      {/* <MobileNavigationBar /> */}
      <Navigation
        ref={navRef}
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        setPageChange={setPageChange}
        navigationThemes={navigationPageThemes}
        location={location}
        theme="light"
        partial={0.5}
        setPage={setPage}
      />
      {/* </NavigationBar> */}
      <Scroll
        isModalOpen={isModalOpen}
        pageThemes={pageThemes}
        location={location}
        pageChange={pageChange}
        setPageChange={setPageChange}
        transitionTimeoutMS={600}
        page={page}
        setPage={setPage}
      >
        <PageWrapper animation={pageChange}>
          <PageTitle subtitle={t(`mainPage_title`)} />
          <TransitionWrapper>{transitionBlocks}</TransitionWrapper>
          <EUModalWrapper>
            <EUModal isEUModalVisible={true} />
          </EUModalWrapper>
          <Navbar>
            <DesktopLogo />
          </Navbar>
          <ContentWrapper modal={modal}>
            <Title>
              <PageTitleWrapper>{t(`mainPage_title`)} </PageTitleWrapper>
              <PageSubtitleWrapper>
                {t(`mainPage_subtitle`)}{" "}
              </PageSubtitleWrapper>
            </Title>
            <ContactButton setModalState={setModalState} />
            <NewsButton setModalState={setModalState} />
          </ContentWrapper>
          <Team fluid={img.mainPhoto.nodes[0].childImageSharp.fluid} />
          <SMWidget />
        </PageWrapper>
        <WhoWeAreMobile pageChange={pageChange} />
        <TechnologiesMobile
          pageChange={pageChange}
          transitionBlocks={transitionRandomBlocks}
          setOpen={setModalState}
        />
        <HistoryMobile pageChange={pageChange} />
        <ClientsMobile
          pageChange={pageChange}
          transitionBlocks={transitionRandomBlocks}
        />
        <CSRMobile
          pageChange={pageChange}
          pageThemeChange={navRef?.current?.setPageTheme}
        />
        <AknowledgementsMobile pageChange={pageChange} />
        <EKOMobile pageChange={pageChange} />
        <OKEMobile pageChange={pageChange} />
      </Scroll>
    </>
  ) : (
    <>
      <Navigation
        ref={navRef}
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        setPageChange={setPageChange}
        navigationThemes={navigationPageThemes}
        location={location}
        theme="light"
        setPage={setPage}
      />

      <Scroll
        isModalOpen={isModalOpen}
        pageThemes={pageThemes}
        location={location}
        pageChange={pageChange}
        setPageChange={setPageChange}
        transitionTimeoutMS={600}
        page={page}
        setPage={setPage}
      >
        <PageWrapper animation={pageChange}>
          <PageTitle subtitle={t(`mainPage_title`)} />
          <TransitionWrapper>{transitionBlocks}</TransitionWrapper>
          <EUModalWrapper>
            <EUModal isEUModalVisible={true} />
          </EUModalWrapper>
          <TopVector />
          <Navbar>
            <DesktopLogo />
          </Navbar>
          <ContentWrapper modal={modal}>
            <Title>
              <PageTitleWrapper>
                <div>{t(`mainPage_title1`)}</div>
                <div>{t(`mainPage_title2`)}</div>
              </PageTitleWrapper>
              <PageSubtitleWrapper>
                {t(`mainPage_subtitle`)}{" "}
              </PageSubtitleWrapper>
            </Title>
            <ContactButton setModalState={setModalState} />
            <NewsButton setModalState={setModalState} />
          </ContentWrapper>
          <Team fluid={img.mainPhoto.nodes[0].childImageSharp.fluid} />
          <BottomVector />

          <SMWidget />
        </PageWrapper>
        <WhoWeAreDesktop pageChange={pageChange} />
        <TechnologiesDesktop
          pageChange={pageChange}
          transitionBlocks={transitionRandomBlocks}
        />
        <HistoryDesktop pageChange={pageChange} />
        <ClientsDesktop
          pageChange={pageChange}
          transitionBlocks={transitionRandomBlocks}
        />
        <CSRDesktop pageChange={pageChange} />
        <Aknowledgements pageChange={pageChange} />
        <EKODesktop pageChange={pageChange} />
        <OKEDesktop pageChange={pageChange} />
      </Scroll>
    </>
  );
};

export const query = graphql`
  {
    mainPhoto: allFile(filter: { relativeDirectory: { eq: "homePageTeam" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default MainPage;
